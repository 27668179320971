// provide a namespace
if (!window.afiworkerp) window.afiworkerp = {};

afiworkerp.provide = function (namespace) {
	// docs: create a namespace //
	var nsl = namespace.split(".");
	var parent = window;
	for (var i = 0; i < nsl.length; i++) {
		var n = nsl[i];
		if (!parent[n]) {
			parent[n] = {};
		}
		parent = parent[n];
	}
	return parent;
};

afiworkerp.provide("locals");
afiworkerp.provide("afiworkerp.flags");
afiworkerp.provide("afiworkerp.settings");
afiworkerp.provide("afiworkerp.utils");
afiworkerp.provide("afiworkerp.ui.form");
afiworkerp.provide("afiworkerp.modules");
afiworkerp.provide("afiworkerp.templates");
afiworkerp.provide("afiworkerp.test_data");
afiworkerp.provide("afiworkerp.utils");
afiworkerp.provide("afiworkerp.model");
afiworkerp.provide("afiworkerp.user");
afiworkerp.provide("afiworkerp.session");
afiworkerp.provide("afiworkerp._messages");
afiworkerp.provide("locals.DocType");

// for listviews
afiworkerp.provide("afiworkerp.listview_settings");
afiworkerp.provide("afiworkerp.tour");
afiworkerp.provide("afiworkerp.listview_parent_route");

// constants
window.NEWLINE = "\n";
window.TAB = 9;
window.UP_ARROW = 38;
window.DOWN_ARROW = 40;

// proxy for user globals defined in desk.js

// API globals
window.cur_frm = null;
