// for translation
afiworkerp._ = function (txt, replace, context = null) {
	if (!txt) return txt;
	if (typeof txt != "string") return txt;

	let translated_text = "";

	let key = txt; // txt.replace(/\n/g, "");
	if (context) {
		translated_text = afiworkerp._messages[`${key}:${context}`];
	}

	if (!translated_text) {
		translated_text = afiworkerp._messages[key] || txt;
	}

	if (replace && typeof replace === "object") {
		translated_text = $.format(translated_text, replace);
	}
	return translated_text;
};

window.__ = afiworkerp._;

afiworkerp.get_languages = function () {
	if (!afiworkerp.languages) {
		afiworkerp.languages = [];
		$.each(afiworkerp.boot.lang_dict, function (lang, value) {
			afiworkerp.languages.push({ label: lang, value: value });
		});
		afiworkerp.languages = afiworkerp.languages.sort(function (a, b) {
			return a.value < b.value ? -1 : 1;
		});
	}
	return afiworkerp.languages;
};
